import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/index.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/contact.vue")
  },
  {
    path: "/hosting",
    name: "Hosting",
    component: () => import("../views/hosting.vue")
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/services.vue")
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/team.vue")
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/terms.vue")
  },
  {
    path: "/donate",
    name: "Donate",
    redirect: "https://www.patreon.com/seismiccore"
  },
  {
    path: "/support",
    name: "Support",
    redirect: "https://discord.com/invite/ejQBeYq"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
